@import '@/styles/styles';

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin: 32px 0 60px 0;

  .shareIcons {
    margin-right: 64px;

    > div {
      margin: 0;
    }
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin: 24px 0 32px 0;

    .shareIcons {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  .content {
    color: $text-color-primary-dark;

    font-size: 18px;
    line-height: 24px;
    max-width: 946px;
    width: 100%;

    .item {
      display: flex;
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 23px;
      }

      &.list {
        font-weight: 700;

        .arrow {
          background-color: $primary;
          border-radius: 2px;
          flex-shrink: 0;
          height: 2px;
          margin-right: 24px;
          margin-top: 12px;
          position: relative;
          width: 34px;

          &::after,
          &::before {
            background-color: $primary;
            border-radius: 2px;
            content: '';
            height: 2px;
            position: absolute;
            right: 0;
            top: 0;
            width: 10px;
          }

          &::after {
            transform: rotate(45deg);
            transform-origin: bottom right;
          }

          &::before {
            transform: rotate(-45deg);
            transform-origin: top right;
          }
        }
      }
    }
  }
}
