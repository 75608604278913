@import 'styles/styles';

@mixin resetAnchor {
  color: inherit;
  text-decoration: none;
}

@mixin linesLimit($maxLine) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $maxLine; /* number of lines to show */
  line-clamp: $maxLine;
  -webkit-box-orient: vertical;
}

.root {
  text-decoration: none;
  width: 100%;
  font-family: $body-font-family;
  display: flex;
  flex-direction: column;
  gap: 32px;
  cursor: pointer;
  position: relative;

  &:hover {
    .zoomedImage {
      transform: scale(1.1);
    }
  }
  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
  &.dark {
    background-color: $text-color-primary-dark;
    .category {
      color: $primary;
    }
    .dotDivider {
      color: $dark-grey;
    }
    .date,
    .title,
    .description {
      color: $text-color-primary-light;
    }
  }

  &.large {
    display: grid;
    //flex-direction: row;
    //align-items: stretch;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    .zoomedImageContainer {
      padding-top: 0;
    }
    .main {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-right: 24px;
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .title {
      @include linesLimit(4);
    }
    .description {
      @include linesLimit(4);
    }
  }
  &.dark.large.imageFullBleed {
    position: relative;
    gap: 0;
    .zoomedImageContainer {
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .main {
      padding-left: 24px;
      position: relative;
      grid-column: 2;
      background: rgba(15, 23, 42, 0.75);
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.information {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.date {
  font-weight: 600;
  color: $text-color-secondary-dark;
}

.category {
  @include resetAnchor;
  color: $darkest-blue;
  font-weight: 800;
}

.dotDivider {
  color: $dark-grey;
}

.title {
  color: $text-color-primary-dark;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  @include linesLimit(2);
}

.description {
  color: $text-color-secondary-dark;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  @include linesLimit(3);
}

.zoomedImageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 60.44%;
  background-color: $dark-grey;
}

.zoomedImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateZ(0);
  transition: transform 300ms ease-in;
  background-size: cover;
  background-position: center;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
