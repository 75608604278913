@import '@/styles/styles';

.container {
  margin-left: calc(50% - 50vw);
  overflow: hidden;
  position: relative;
  width: 100vw;

  .box {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @include layout();
  }

  :global {
    .swiper {
      overflow: visible;

      .swiper-wrapper {
        .swiper-slide {
          width: auto;
        }
      }
    }
  }
}
