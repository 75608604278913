@import '@/styles/styles';

.container {
  color: $text-color-primary-dark;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin: 60px 0;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    margin: 32px 0 64px 0;
  }
}

.summingUp {
  font-family: $title-font-family;
  font-size: 64px;
  font-weight: 400;
  line-height: 64px;
  margin-right: 24px;
  overflow: hidden;

  &:after {
    border-radius: 2px;
    background-color: $primary;
    content: '';
    display: inline-block;
    height: 4px;
    margin-right: -100%;
    max-width: 157px;
    margin-bottom: 10px;
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 28px;
    margin-right: 0;

    &:after {
      display: block;
      margin-top: 32px;
      padding-bottom: 0;
      max-width: 70px;
    }
  }
}

.items {
  max-width: 754px;
  width: 100%;
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 44px;
    }

    .number {
      color: $dark-grey;
      font-family: $title-font-family;
      font-feature-settings: 'tnum' on, 'lnum' on, 'ordn' on;
      font-size: 120px;
      font-weight: 400;
      line-height: 100px;
      margin-right: 24px;
      font-style: normal;
    }
    .content {
      .title {
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 19px;
      }
      .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    @include media-breakpoint-down(lg) {
      &:not(:last-child) {
        margin-bottom: 21px;
      }

      .number {
        font-size: 80px;
        line-height: 100px;
      }

      .content {
        .title {
          @include media-breakpoint-down(lg) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
