@import '@/styles/_styles.scss';

.title {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  font-family: $title-font-family;
  margin-bottom: 64px;
  @include media-breakpoint-down(lg) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px;
  }
}

.root {
  margin-top: 120px;
  @include media-breakpoint-down(lg) {
    margin-top: 64px;
  }
}
