@import '@/styles/styles';

.container {
  padding: 138px 0 120px 0;
  position: relative;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding: 122px 0 64px 0;
  }
}

.background {
  left: 0;
  margin-left: calc(50% - 50vw);
  position: absolute;
  width: 100vw;

  &Top {
    height: 11%;
    top: 0;
  }
  &Bottom {
    bottom: 0;
    height: 89%;
  }
}

.box {
  color: $text-color-primary-dark;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  text-decoration: none;

  &:hover {
    .left {
      .image {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }

    .right {
      .textCaption {
        .arrow {
          transform: translateX(10px);
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.left {
  margin-right: 32px;
  margin-top: -138px;
  max-width: 558px;
  overflow: hidden;
  position: relative;
  width: 100%;

  .imageContainer {
    background-color: $dark-grey;
    height: 0;
    padding-top: calc((620 / 558) * 100%);
    position: relative;
  }

  @include media-breakpoint-down(md) {
    margin: -122px auto 64px;
    max-width: 327px;
    .imageContainer {
      padding-top: 100%;
    }
  }

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 100%;
  }
}

.right {
  margin-top: auto;
  max-width: 461px;
  width: 100%;
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }

  .textCaption {
    align-self: center;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 32px;
    white-space: nowrap;

    .arrow {
      background-color: $dark-coal;
      border-radius: 2px;
      display: inline-block;
      height: 2px;
      margin: auto;
      margin-left: 16px;
      position: relative;
      transition: all 0.3s ease;
      width: 100%;

      &::before,
      &::after {
        background-color: $dark-coal;
        border-radius: 2px;
        content: '';
        height: 2px;
        position: absolute;
        right: 1px;
        top: 0;
        transform-origin: center right;
        width: 10px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .info {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 9px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 3px;
    }

    .category {
      font-weight: 800;
    }
  }

  .title {
    font-family: $title-font-family;
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
    margin-bottom: 32px;
    @include linesLimit(5);

    @include media-breakpoint-down(lg) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .author {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .avatarContainer {
      border-radius: 50%;
      height: 48px;
      margin-right: 16px;
      overflow: hidden;
      position: relative;
      width: 48px;

      .avatar {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 48px;
        width: 48px;
      }
    }

    .name {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    .jobTitle {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}
