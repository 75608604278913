@import 'styles/styles';

.zoomedImageContainer {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  padding-top: 100%;
  flex-shrink: 0;
  width: 100%;
  transform: translateZ(0);
}

.zoomedImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateZ(0);
  transition: transform 300ms ease-in;
  background-size: cover;
  background-position: center;
}

.avatar {
  width: 48px;
}

.root {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .zoomedImage {
      transform: scale(1.1);
    }
  }

  &.dark {
    .name,
    .jobTitle {
      color: $text-color-primary-light;
    }
  }
}

.info {
  flex-grow: 1;
}

.name {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $text-color-primary-dark;
  @include linesLimit(1);
}

.jobTitle {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $text-color-secondary-dark;
  @include linesLimit(1);
}
