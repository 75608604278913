@import '@/styles/styles';

.container {
  display: flex;
  flex-direction: row;
  margin: 40px auto 120px auto;
  max-width: 872px;
  position: relative;
  transform: none;
  width: 100%;
  width: fit-content;
  z-index: 1;

  .socialAndShareLink {
    cursor: pointer;
    display: inline-block;
    height: 32px;
    position: relative;
    width: 32px;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 24px;
    }

    &:hover {
      opacity: 0.7;
      .socialAndShareTooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.vertical {
    flex-direction: column;
    margin: 0;

    .socialAndShareLink {
      &:not(:last-child) {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    margin: 16px auto 64px auto;
  }
}

.socialAndShareTooltip {
  background-color: #555;
  border-radius: 6px;
  bottom: 125%;
  color: #fff;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  padding: 8px 5px;
  position: absolute;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  user-select: none;
  visibility: hidden;
  white-space: nowrap;
  width: 160px;
  z-index: 1;
  &::after {
    border-color: #555 transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    left: 50%;
    margin-left: -25px;
    position: absolute;
    top: 100%;
  }
}
