@import '@/styles/styles';

.container {
  max-width: 849px;
  width: 100%;
  position: relative;
  margin: 64px 0 32px 0;

  @include media-breakpoint-down(lg) {
    margin: 32px 0 24px 0;
  }
}

.textCaption {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  font-family: $title-font-family;
  color: $dark-coal;
  p {
    margin: 0;
  }

  @include media-breakpoint-down(lg) {
    font-size: 40px;
    line-height: 40px;
  }
}
