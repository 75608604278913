@import '@/styles/styles';

.container {
  padding-top: 180px;
  padding-bottom: 80px;
  position: relative;
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);

  @include media-breakpoint-down(lg) {
    padding-top: 154px;
    padding-bottom: 31px;
  }
}

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  margin-left: calc(50% - 50vw);
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;

  &::after {
    background-color: rgba($color: $dark-coal, $alpha: 0.75);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.url {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0%;
  opacity: 0;
}

.content {
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include layout();

  .title {
    color: $white;
    font-family: $title-font-family;
    font-size: 120px;
    font-weight: 400;
    line-height: 120px;
    margin-bottom: 64px;
    @include linesLimit(4);

    @include media-breakpoint-down(lg) {
      font-size: 64px;
      line-height: 64px;
    }
  }

  .arrow {
    background-color: $primary;
    border-radius: 2px;
    height: 2px;
    margin-left: auto;
    position: relative;
    width: 50%;
    margin-bottom: 161px;
    transition: all 0.3s ease;

    @include media-breakpoint-down(lg) {
      margin-bottom: 121px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    &::before,
    &::after {
      background-color: $primary;
      border-radius: 2px;
      content: '';
      height: 2px;
      position: absolute;
      right: 1px;
      top: 0;
      transform-origin: center right;
      width: 10px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .titleBox {
    cursor: pointer;
    position: relative;
  }

  .titleBox:hover {
    .arrow {
      width: calc(50% + 10px);
      transform: translateX(10px);

      @include media-breakpoint-down(md) {
        width: 101%;
        transform: unset;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $light-grey;
    margin-bottom: 64px;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
